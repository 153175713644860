import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Sidebar from './Sidebar'
import CopyContainer from './CopyContainer'

import './CopyWithSidebar.scss'

function CopyWithSidebar(props) {

  return (
    <div className="CopyWithSidebar">
      <h1>
        {props.title}
      </h1>
      <div className="CopyWithSidebar-grid">
        <Sidebar />
        <div className="CopyWithSidebar-copy">
          <CopyContainer>
            <div className="CopyWithSidebar-version">
              Version: {props.version}
            </div>
            <div className="CopyWithSidebar-updated-date">
              Updated: {props.updated}
            </div>
            <div className="CopyContainer-body" dangerouslySetInnerHTML={{ __html: props.copy.html }} />
          </CopyContainer>
        </div>
      </div>
    </div>
  )
}

export default CopyWithSidebar
