import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import './Sidebar.scss'

function Sidebar() {

  return (
    <div className="Sidebar">
      <div className="Sidebar-nav-container">
        <div className="Sidebar-nav">
          <ul>
            <li className="active">
              <Link className="Link" to="/concepts#intro">Introduction</Link>
            </li>
            <li>
              <Link className="Link" to="/concepts#ari">ARI</Link>
            </li>
            <li>
              <Link className="Link" to="/concepts#linkbase">Linkbase</Link>
            </li>
            <li>
              <Link className="Link" to="/concepts#user-agent">User Agent</Link>
            </li>
            <li>
              <Link className="Link" to="/concepts#rules">Rules</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
