import React from 'react'
import './CopyContainer.scss'

function CopyContainer(props) {

  return (
      <div className="CopyContainer" data-centred={props.centred}>
        {props.children}
      </div>
  )
}

export default CopyContainer
