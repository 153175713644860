import React from 'react'
import { Link, graphql } from 'gatsby'

import Environment from '../lib/Environment'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import CopyWithSidebar from '../components/CopyWithSidebar'

import './concepts.scss'


class Concepts extends React.Component {

  render () {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    const content = data.markdownRemark

    return (
      <Layout root={true} location={this.props.location} title={siteTitle}>
        <SEO
          title="Concepts"
          keywords={[`concepts`]}
        />
        <CopyWithSidebar title={content.frontmatter.title} version={content.frontmatter.version} updated={content.frontmatter.updated} copy={content} />
      </Layout>
    )
  }
}

export default Concepts

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: "/concepts" } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        version
        updated(formatString: "D MMMM YYYY")
      }
    }
  }
`
